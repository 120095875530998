<template>
    <div class="box">
        <h4> PRICE</h4>
        <h5> ${{ price }}</h5>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';

export default {
    setup() {
        const price = ref(0)

        const xch_price = () => {
            fetch("https://xchscan.com/api/chia-price")
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    price.value = data.usd;
                })
                .catch(function (error) {
                    console.error(error)
                })
        }

        onMounted(() => {
            xch_price()
            setTimeout(() => {
                xch_price()
            }, 5000)
        })

        return { price }
    },
    mounted() {
    },
};
</script>

<style scoped>
h4,
h5 {
    margin: 0;
}

.box {
    opacity: 0.75;
    color: white;
}
</style>
