<template>
    <header>
        <nav>
            <h1>
                ≈
            </h1>
        </nav>
        <section id="header_widgets">
            <XchPrice style="margin: auto 5px auto 5px" />
            <XchNSpace style="margin: auto 20px auto 5px" />
        </section>
    </header>

    <main>
        <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ex sunt provident autem dignissimos fugit repudiandae
            quos, aliquid doloribus consectetur cumque quod pariatur? Ut esse totam consequatur assumenda consectetur,
            libero quidem eligendi similique, numquam cum provident eveniet placeat magni consequuntur asperiores laudantium
            debitis facere nihil? Consequuntur rerum, sunt repellendus id necessitatibus assumenda eum quos laboriosam
            eveniet odit magnam mollitia excepturi modi, quis omnis porro dignissimos deserunt nihil consectetur, unde
            minima vero. Eveniet animi amet molestiae? Iure quasi sint cum qui consequuntur delectus tempora inventore, ex
            hic ullam. Voluptatem, quidem? Minima, atque fugit suscipit ab eligendi dolor consequuntur id, voluptas eaque
            fuga debitis culpa, porro assumenda neque libero. Amet rerum ut consectetur voluptatum, esse recusandae culpa
            tenetur, beatae distinctio saepe tempora deleniti nostrum officia quis at cumque similique deserunt. Excepturi
            provident dolore delectus ipsam tenetur officia obcaecati exercitationem beatae ex? Temporibus adipisci incidunt
            facilis molestiae, corporis veniam mollitia eum velit rerum consequatur possimus ab qui odit atque placeat sint.
            Quibusdam, ipsam quos? Repellendus aliquid officiis mollitia possimus facilis, cupiditate excepturi fuga
            exercitationem amet, ex quis, saepe laborum consequuntur dolorum autem nemo. Repellat ut sapiente consectetur
            ex! Magni dolores repellendus maiores architecto quos nostrum delectus voluptatem corporis consequuntur quis ad
            sequi porro temporibus vel dolorum, accusamus inventore doloremque reiciendis quo tempora explicabo! Asperiores
            eum voluptates, adipisci autem quam sequi delectus deserunt aliquam error. Consectetur inventore fugiat unde
            vero error fugit nesciunt officiis veniam eveniet nemo laboriosam eos odit quod temporibus nostrum similique
            esse mollitia voluptatem minima quas, exercitationem ratione ipsum est ipsam. Dolor eum fugiat reiciendis
            officia odit excepturi asperiores ut quod. Ea repudiandae iste id excepturi unde inventore obcaecati odio
            quisquam ratione, qui molestias asperiores est culpa quod nobis animi non voluptatem similique necessitatibus
            libero eum quia rerum. Excepturi, dolorem cum fugit corporis et nulla quae rerum suscipit doloremque, reiciendis
            a ratione dolore ex nesciunt commodi harum perferendis minima modi odit? Cum hic excepturi ea explicabo, maiores
            minus voluptas dolor quia totam maxime dolorem officia alias ullam laudantium harum exercitationem saepe optio?
            Reprehenderit est expedita voluptate odit laborum placeat aut vitae minus voluptates quos velit doloremque
            consequatur facere culpa corrupti molestiae quidem quo blanditiis, dicta, ut quia. Quaerat, consequatur. Nemo,
            magni ab est temporibus earum voluptatum vel necessitatibus numquam odio beatae nostrum aliquid pariatur.
            Consequuntur consectetur perspiciatis quod, dolores, autem, asperiores labore adipisci omnis eius ad doloremque
            tenetur ullam! Ipsa harum, sed, explicabo voluptatibus magnam commodi quaerat labore nobis quasi, iste
            doloribus. Vel qui quidem impedit hic eius voluptatum, iusto magni pariatur maiores modi porro unde nesciunt
            accusamus quos voluptates, molestias, ipsam quis expedita odio dolor explicabo illo! Ipsum similique quo earum
            error corrupti provident. Sit inventore error minus, sapiente, vero libero ullam voluptas ea quam, recusandae
            quidem enim itaque ducimus? Repellat ducimus culpa ea iste magni! Rerum provident sint explicabo nisi illum,
            laborum atque? Eaque provident animi, blanditiis nihil dolores et impedit voluptatum, harum suscipit repudiandae
            molestiae pariatur soluta sint architecto labore temporibus, similique illo at odit facilis sunt sit magnam
            dignissimos ipsam! Harum veritatis, libero necessitatibus, omnis sint aspernatur repellat doloribus deleniti
            debitis consequuntur id, est natus molestias error sed at quas autem consequatur nemo laudantium impedit ipsum
            animi accusamus. Nemo nobis quae repudiandae rem rerum iure laborum, ducimus voluptatem eveniet distinctio
            cupiditate perspiciatis inventore pariatur facilis veniam. Quisquam beatae soluta at. Rerum consectetur
            accusamus dolor nisi deserunt officiis nam similique beatae repellendus adipisci voluptatem necessitatibus,
            reiciendis ullam deleniti dolorum. Beatae eos minima reprehenderit tempore distinctio ratione a quidem
            voluptatem eligendi vero deleniti nisi atque possimus nemo quam dolores similique reiciendis, veritatis nulla,
            consequuntur maxime totam sunt. Adipisci ratione pariatur nisi, fuga quos tempore cupiditate. Facere, eveniet
            unde sunt nam iusto iure obcaecati dolorem? Aperiam, accusantium culpa. Eaque vel sit labore, quia illo id vitae
            soluta, obcaecati aspernatur optio quae sed porro temporibus? Nam atque omnis voluptatibus quaerat soluta
            adipisci tempora possimus ex quo, incidunt quia laborum, voluptas fugit tenetur, aperiam quisquam animi
            consequuntur sunt expedita? Vitae, nesciunt voluptates eos qui accusantium voluptatum impedit, modi odio nemo ea
            tenetur quod assumenda consequuntur adipisci architecto saepe alias! Hic maxime quae earum dolorem. Corporis
            aperiam iusto aliquam ipsam recusandae autem ducimus beatae ratione sequi molestias esse, cumque soluta ab!
            Doloribus rerum illo excepturi beatae consectetur repellendus soluta nisi repudiandae provident, dolorum
            sapiente! Ad ipsum quod, magnam cumque tenetur ducimus beatae recusandae? Omnis laboriosam, quis maxime cum quos
            dolorum? Delectus, accusamus doloremque? Aliquam odio dolores necessitatibus debitis distinctio accusantium
            earum esse nobis rerum natus ea, aperiam iste quis possimus veritatis dignissimos, tenetur minima. Maxime, quia.
            Alias, eum architecto consectetur ex, suscipit placeat dignissimos vitae porro optio minima quidem. Accusantium
            nobis quia voluptates laudantium dicta consequuntur perspiciatis praesentium ad. Recusandae pariatur tempore
            deleniti placeat architecto provident non ex unde eveniet beatae sed eaque excepturi repellendus magnam commodi
            reiciendis culpa, molestias odit, reprehenderit veniam doloremque aspernatur animi obcaecati. Nemo consectetur
            dolore quos, fuga voluptatum odio? A soluta, esse, odio sapiente fugiat asperiores aliquam sed nobis quis
            itaque, suscipit quae iusto magnam deleniti nam obcaecati illo laudantium dicta accusamus amet corrupti
            molestias reprehenderit eius? Obcaecati blanditiis inventore nam officia fuga laborum, architecto debitis optio
            officiis quo iure quasi natus nemo iusto, consectetur aperiam laboriosam nulla dolor pariatur! Earum quos velit
            blanditiis similique incidunt eaque fugiat! Beatae est numquam aut similique, quas laboriosam saepe quisquam at
            rerum repellendus? Ipsum, debitis pariatur. Voluptas maxime, voluptatibus ullam aliquam pariatur id porro
            provident voluptatem ratione quisquam dignissimos soluta nam cumque voluptate consectetur adipisci? Vero ratione
            corrupti quidem quas sit dignissimos dolorem atque fugiat iusto architecto nisi eveniet animi unde labore, minus
            a corporis nihil, dicta, at cum distinctio placeat quibusdam neque quam. Maxime nemo, excepturi nesciunt commodi
            nobis quaerat, atque repellendus, dolorem aperiam voluptatum ipsam aut rerum cum vitae mollitia possimus modi
            fugiat dolore repudiandae at fugit architecto. Quos omnis ratione optio soluta maxime odio! Blanditiis
            consectetur impedit omnis nobis amet dolore veniam reiciendis neque quibusdam ut assumenda deserunt quas est
            earum, nesciunt sed enim incidunt iure! Ducimus similique esse cum ullam a ipsam vero, ab voluptate enim
            aspernatur consectetur accusantium deserunt recusandae perferendis, voluptatibus ipsa est odit consequatur
            reprehenderit non error ad nisi dicta. Quisquam illo, sit, ut vel nobis odit similique dolores iure dignissimos
            quae, tempore vero quas. Laborum reiciendis aliquam minima rem debitis optio, sed voluptatem consequuntur, porro
            voluptatibus officiis neque quaerat illo quae dolore ipsa. Commodi quia vero ea repudiandae sapiente, ab
            voluptas vitae inventore perferendis magni harum odio distinctio adipisci neque quidem! Voluptatibus magni
            sapiente nihil eos, molestiae tempora repellat consectetur ipsa est accusantium odio laboriosam omnis a aliquid
            cum. Dignissimos quis enim aperiam ratione. Optio aut voluptatem quod, commodi, reiciendis perferendis
            perspiciatis alias omnis maxime tenetur porro non error distinctio quas fugit! Ipsum ea eum, nobis perferendis
            sit autem deserunt aut delectus at dignissimos esse laboriosam modi id! Hic, quaerat! Vel suscipit eligendi
            exercitationem impedit nemo facere necessitatibus nesciunt. Atque nesciunt quia consectetur aliquid ad, cumque,
            quasi placeat corrupti sunt velit quam magni. Quas reiciendis corrupti sequi illo, totam doloremque itaque
            accusamus nihil praesentium veritatis qui exercitationem, at commodi magnam accusantium iusto recusandae error
            quo asperiores tempora. Praesentium numquam eaque dicta neque sunt, quaerat nostrum consequatur a, mollitia
            sapiente repellendus. Eaque quasi tempora deserunt vero aliquid est sequi dignissimos reprehenderit nisi ab
            fugit voluptatem ipsum, vitae repudiandae sed voluptas, qui reiciendis cupiditate totam possimus libero
            recusandae. Et fuga provident delectus quisquam aperiam iure accusantium magnam temporibus? Obcaecati nulla sint
            veniam ea iste magnam labore numquam, totam consequatur nisi repellendus ipsum dolorum quaerat maxime animi
            delectus ab quo aliquam! Incidunt magnam voluptas nostrum cupiditate commodi ab dicta architecto laudantium.
            Illo necessitatibus sequi magnam, quis adipisci omnis libero blanditiis, natus sint rerum fugit? Autem dolorum
            necessitatibus voluptas quibusdam suscipit earum sapiente, ea corrupti nihil dolore hic delectus unde quis omnis
            quas eligendi, tenetur atque repudiandae eum quo voluptate labore ipsum. Saepe repellat accusantium obcaecati
            placeat alias fugit excepturi quibusdam sunt. Ullam aperiam doloremque suscipit quo voluptatibus harum vero amet
            praesentium labore nostrum deleniti beatae error magni rerum, alias quis quidem soluta ea sed, voluptatem
            magnam, non quae? Inventore, et vero? Alias minus minima ea esse non ipsam earum veniam autem illo quam
            laboriosam iste repellendus recusandae et nam provident ex rerum eum dignissimos repellat odit, optio dolores
            eaque corrupti? Rem vitae nostrum nemo nisi dicta! Eos nisi numquam cumque earum eum aliquid, quidem distinctio
            quam deserunt debitis maxime tempore reiciendis voluptatibus in accusamus itaque eius aspernatur maiores qui.
            Quibusdam consectetur est totam ipsa sunt ullam eligendi, obcaecati iste, exercitationem nesciunt recusandae
            architecto consequuntur incidunt asperiores animi commodi quo nisi excepturi. A perferendis dolor ea id magni
            harum. Perferendis repellendus sed officiis sunt! Repudiandae mollitia odit fugiat, commodi harum aperiam natus
            dicta nulla esse maiores? Consequatur necessitatibus unde, ad corrupti dicta debitis qui omnis dolore
            exercitationem error tenetur reiciendis nostrum quia minus voluptate neque odit ex aut quas facere consequuntur?
            Nesciunt impedit nisi quisquam at blanditiis incidunt vitae. Doloribus, esse voluptatibus odit unde, corporis
            cupiditate laudantium qui voluptas temporibus, adipisci voluptatum accusamus eveniet nostrum magnam atque
            doloremque aperiam aut quis praesentium reiciendis nobis sunt laborum! Maxime, optio perspiciatis. Magnam quos
            perferendis dolores tempore odio aspernatur, doloremque harum aliquam necessitatibus sequi totam eius sapiente
            quasi minus iste porro odit aperiam corrupti blanditiis dolorem ab praesentium obcaecati. Aut praesentium libero
            sapiente ullam facilis fugit nam dicta, veniam debitis obcaecati tenetur molestias, omnis minima repellendus
            quis eaque quaerat dolorum molestiae officia ipsum! Ipsa iste, voluptatem inventore voluptates sapiente nostrum
            vel numquam dolore nihil! Deserunt, harum itaque eius voluptatibus sunt aliquam blanditiis libero facilis ea
            enim et distinctio minus nam quis aliquid ipsum qui. Molestias, quidem voluptatibus tenetur assumenda aspernatur
            quos tempora doloremque quas earum quae recusandae hic magni nisi vero. Earum temporibus maiores, quod molestiae
            quis necessitatibus aperiam enim, totam, rem consectetur ex error officiis adipisci veritatis nihil! Iusto eius
            eaque cum atque voluptatem quis, vel animi officia consectetur distinctio sint iure, minima deserunt repudiandae
            quibusdam inventore adipisci id ipsa suscipit quas, illum maxime aperiam. Possimus aut maiores necessitatibus
            tempora assumenda eum! Ipsum, cum praesentium sapiente nihil accusantium expedita quibusdam eum maiores. Error
            id laboriosam animi voluptatibus illum quos veniam ipsam qui suscipit vitae possimus modi dicta aspernatur,
            libero saepe unde accusantium ad veritatis quibusdam dolorem adipisci? Quaerat aliquid nam tempora reiciendis
            officiis? Necessitatibus fugiat earum velit aut repudiandae vitae tenetur debitis numquam tempora perspiciatis?
            Fuga et est quas rem quia temporibus ratione! Sit quo, autem, dolorum quos quidem asperiores, ad dignissimos
            exercitationem nostrum quisquam fugiat! Amet, similique. Quasi odit ut hic sint error? Dolorum explicabo odio
            laborum animi nostrum error unde exercitationem omnis, itaque fuga quibusdam porro, illum repellendus voluptates
            nemo velit eveniet! Cupiditate possimus doloremque, exercitationem eaque nisi veritatis aspernatur obcaecati
            neque ab est eius deserunt porro at nam itaque consequatur expedita qui mollitia. Adipisci, magnam? Atque amet
            fugiat, necessitatibus dolore sint sunt ex est optio non. Distinctio quam fugit, aperiam possimus modi, aliquam
            porro ex a itaque magnam laborum explicabo, tempora eaque ab? Reiciendis molestias voluptas laboriosam itaque
            sed qui quod voluptatum laudantium laborum! Nesciunt, itaque reiciendis distinctio harum id minus eos, molestias
            quisquam fugiat sequi quod iste illum officiis ipsum est dignissimos et laudantium accusamus libero iure aliquam
            quae similique illo. Rerum, quo quis. Quidem nostrum ex accusantium iusto architecto dignissimos, maiores
            reiciendis maxime expedita a molestiae dolor harum soluta eum dolores nulla ipsam incidunt veritatis. Excepturi
            quae aut sequi qui corporis numquam animi culpa laborum vel ratione perspiciatis praesentium cupiditate autem,
            hic voluptatum temporibus voluptatibus, libero quaerat. Reiciendis possimus quasi numquam veniam. Maxime
            perferendis dolorum laboriosam possimus velit repudiandae illum quibusdam sapiente corrupti excepturi dolore in
            repellat praesentium quisquam perspiciatis, earum quam? Blanditiis nobis dignissimos voluptate culpa aut placeat
            fugiat similique, quisquam iure, optio quis nam nihil ex molestias ad eos consectetur adipisci corrupti.
            Repellendus eaque aut pariatur molestiae? Harum, et sint consequatur placeat eum nisi nam illum dignissimos
            nesciunt distinctio nostrum nobis. Mollitia fugit saepe atque, ipsam, totam vel ipsa asperiores quibusdam
            voluptatem repellendus fuga accusamus aperiam autem corporis consequuntur consectetur explicabo labore harum
            excepturi? Id ratione laudantium facere tempore voluptates similique omnis voluptatem quae ab laborum pariatur,
            corporis impedit necessitatibus repellendus nobis, molestias adipisci ipsam unde nam ducimus nostrum! Inventore
            exercitationem illo consequatur.
        </p>
    </main>

    <footer>
        <!-- <img src="./assets/test.svg" alt="nopic" style="width: 36px; color: white; background-color: grey; margin: auto"> -->
        <h5> DOGDADA.COM</h5>
    </footer>
</template>

<script>
import XchPrice from "./components/XchPrice.vue"
import XchNSpace from "./components/XchNSpace.vue"

export default {
    name: 'App',
    components: {
        XchPrice,
        XchNSpace
    }
}
</script>

<style>
body {
    padding: 0;
    margin: 0;
    position: relative;
    min-height: 100%;
}

main {
    text-align: justify;
    overflow: auto;
    /* background: url(https://wallpaperaccess.com/full/90977.jpg) no-repeat center fixed;
    background-size: cover; */
}

footer {
    background: #333;
    bottom: 0;
    color: white;
    display: flex;
    justify-content: center;
    padding: 5px;
    z-index: 1;
}

footer h5 {
    margin: auto;
}

header {
    position: sticky;
    background: #333;
    top: 0;
    display: flex;
    justify-content: space-between;
    z-index: 1;
}

header h1 {
    margin: auto auto auto 10px;
    color: white;
}

#header_widgets {
    display: flex;
    justify-content: right;
    width: 30%;
    margin: 0;
    padding: 0;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    /* margin-top: 60px; */
}

.svg-snoweb {
    color: white;
    background: #eee;
}
</style>
